
.container-slogan.container-fluid {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5rem 0;
}

.container-slogan .text {
    font-size: 4rem;
    color: white;
}
.container-slogan .text span {
    color: #3a7cf3;
    font-size: 4rem;
}
.container-slogan .mascot {
    margin-right: 2rem;
}