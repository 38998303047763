.icon-box{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5rem 0;
}
.icon-box .main-border{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.icon-box .border-container {
    position: relative;
    width: 9.375rem;
    height: 9.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 6rem;
}

.icon-box .border-top-left, .border-bottom-right {
    position: absolute;
    border: 2px solid #3a7cf3;
}

.icon-box .border-top-left {
    top: 0;
    left: 0;
    width: 4.5rem;
    height: 4.5rem;
    border-right: none !important;
    border-bottom: none !important;
}

.icon-box .border-bottom-right {
    bottom: 0;
    right: 0;
    width: 4.5rem;
    height: 4.5rem;
    border-left: none !important;
    border-top: none !important;
}

.icon-box .content {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.icon-box .icon-icon {
    width: 6rem;
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
}

.icon-box .icon-box-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.icon-box .icon-title {
    text-align: center;
    font-size: 1.1rem;
    margin-top: 1rem;
    color: #fff;
}