.footer-list{
    margin-right: 0.625rem;
    margin-left: 0.625rem;
 
}
.footer-list .footer-list-li{
  list-style: none;
}
.footer-list .footer-list-li a{
    margin-right: 0.625rem;
    font-size: 1.3rem;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s;
    line-height: 2rem;
}
.footer-list .footer-list-li a:hover{
    color: #3B82F6;
   
}

.footer-communication-box .footer-list .footer-list-li:nth-child(2),
.footer-communication-box .footer-list .footer-list-li:nth-child(4),
.footer-communication-box .footer-list .footer-list-li:nth-child(6),
.footer-communication-box .footer-list .footer-list-li:nth-child(7){
    margin-top: 1rem;
}
.footer-communication-box .footer-list .footer-list-li:nth-child(1),
.footer-applications-box .footer-list .footer-list-li:nth-child(1){
    margin-bottom: 1.5rem;
}
.footer-communication-box .footer-list .footer-list-li:nth-child(1) a,
.footer-applications-box .footer-list .footer-list-li:nth-child(1) a{
    font-size: 1.5rem;
}