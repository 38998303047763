div.about-us .image-frame-box-image-text-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
}

div.about-us .image-frame-box-image-text-box .image-frame-box{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
div.about-us .image-frame-box-image-text-box .image-frame-box .main-border{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

div.about-us .image-frame-box-image-text-box .image-frame-box .border-container {
    position: relative;
    max-width: 44.625rem;
    max-height: 32.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

div.about-us .image-frame-box-image-text-box .image-frame-box .border-top-left, .border-bottom-right {
    position: absolute;
    border: 2px solid #3a7cf3;
}

div.about-us .image-frame-box-image-text-box .image-frame-box .border-top-left {
    top: -5rem;
    left: -10rem;
    width: 15.625rem;
    height: 15.625rem;
    border-right: none;
    border-bottom: none;
}

div.about-us .image-frame-box-image-text-box .image-frame-box .border-bottom-right {
    bottom: -5rem;
    right: -10rem;
    width: 15.625rem;
    height: 15.625rem;
    border-left: none;
    border-top: none;
}

div.about-us .image-frame-box-image-text-box .image-frame-box .content {
    display: flex;
    flex-direction: column;
    align-items: center;

}

div.about-us .image-frame-box-image-text-box .image-frame-box .image-image {
    max-width: 20rem;
    max-width: 20rem;
    width: 17.5rem;
}

div.about-us .image-frame-box-image-text-box .image-text-box{
max-width: 100% ;
padding-left: 0;
padding-right: 6rem;
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: column;
}
div.about-us .image-frame-box-image-text-box .image-text-box .image-text-box-title{
display: block;
width: 100%;
justify-content: start;
align-items: start;

}
div.about-us .image-frame-box-image-text-box .image-text-box .image-text-box-title .title-white{
    display: block;
    margin-right: 1rem;
    color: white;
}
div.about-us .image-frame-box-image-text-box .image-text-box .image-text-box-title .title-blue{
    display: block;
    color: #3a7cf3;
}
div.about-us .image-frame-box-image-text-box .image-text-box .image-text-box-description{
    display: block;
    color: white;
}


div.about-us .image-frame-box-image-text-box .image-text-box .image-text-box-title h3:nth-child(1){
font-size: 2.5rem;
}
div.about-us .image-frame-box-image-text-box .image-text-box .image-text-box-title h3:nth-child(2){
font-size: 2rem;
margin:4rem 0;
}
div.about-us .image-frame-box-image-text-box .image-text-box .image-text-box-description p{
padding-right: 2rem;
font-size: 1.2rem;

}