#root{
    font-family: "BebasNeue-Regular", sans-serif;
    font-style: normal;
}

.section-mining-tunneling.container{
    max-width: 95.625rem;
}

.section-mining-tunneling h2{
    font-family: "BebasNeue-Regular", sans-serif;
    font-style: normal;
    font-size: 4rem;
    margin-bottom: 0;
    padding-bottom: 0;
}
.section-mining-tunneling h3{
    font-family: "BebasNeue-Regular", sans-serif;
    font-style: normal;
    font-size: 2.5rem;
    margin-bottom: 0;
    padding-bottom: 0;
}
.section-mining-tunneling p{
    font-family: "BebasNeue-Regular", sans-serif;
    font-style: normal;
    font-size: 1.5rem;
}

.section-mining-tunneling .section-title-white{
    color: #fff;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
    padding: 0.5rem 0;

}
.trademark {
    font-size: 0.5em; /* Smaller size for superscript */
    vertical-align: super;
}


.section-mining-tunneling .section-title-blue{
    color: #3a7cf3;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
    padding: 0.5rem 0;
    
}

.section-mining-tunneling .section-title-description{
    color: #fff;
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    margin: 0;
    padding: 2rem 0;
}

.section-title-image img{
width: 100%;
}