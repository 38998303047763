.offcanvas-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 50%;
  max-width: 100%;
  height: 100%;
  background: linear-gradient(270deg, #052353f2 0%, #08142d71 50%, #02081700 100%);
  z-index: 1130;
  transform: translateX(101%);
  transition: all 0.7s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.offcanvas-menu.open {
  opacity: 1;
  visibility: visible;
  transition: all 0.7s ease 500ms;
  transform: translateX(0%);
}

.offcanvas-menu .offcanvas-wrapper {
  position: relative;
}

.offcanvas-menu .close-btn {
  position: absolute;
  right: 5rem;
  top: 5rem;
  line-height: 1.875rem;
  width: 1.875rem;
  text-align: center;
  color: #000000;
  cursor: pointer;
  z-index: 10;
  transition: all 0.5s ease;
  border: none;
}

.offcanvas-menu .close-btn svg {
  color: white;
  font-size: 3rem;
}

.offcanvas-menu .close-btn .menu-close {
  background-color: transparent;
  border: none;
}

.offcanvas-menu .logo {
  position: relative;
  padding: 1.25rem 1.25rem;
  text-align: left;
}

.offcanvas-menu .logo img {
  max-width: 12.5rem;
}

.offcanvas-menu .primary-menu {
  margin-top: 1.5rem;
}

.offcanvas-menu .primary-menu ul {
  border-top: none;
}

.offcanvas-menu .primary-menu ul li {
  position: relative;
  display: block;
  border-bottom: none;
}

.offcanvas-menu .primary-menu ul li a {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 2rem;
  padding: 0.875rem 0px;
  font-size: 2rem;
  color: white;
  transition: all 0.5s ease;
  text-decoration: none;
}

.offcanvas-menu .primary-menu ul li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin: auto;
  background: white;
  transition: width 0.5s ease;
  bottom: -5px;
  left: 0%;
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
}

.offcanvas-menu .primary-menu ul li .mobile-menu-expand {
  display: block;
  position: absolute;
  width: 3.25rem;
  height: 3.25rem;
  top: 0;
  right: 0;
}

.offcanvas-menu .primary-menu ul li .mobile-menu-expand::before {
  position: absolute;
  content: '';
  width: 0.5rem;
  height: 0.5rem;
  display: block;
  position: absolute;
  top: 10.5rem;
  right: 1.25rem;
  transform: rotate(-45deg);
}

.offcanvas-menu .primary-menu ul li .mobile-menu-expand::after {
  content: '';
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 1.5rem;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.offcanvas-menu .primary-menu ul li .sub-menu li:last-child {
  border-bottom: 0;
}

.overlay {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1120;
  background: rgba(0, 0, 0, 0.9);
  transform: translateX(101%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.7s ease 500ms;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
  transition: all 0.7s ease;
  transform: translateX(0%);
}

.arrow-hover {
  position: absolute;
  left: -1.875rem;
  opacity: 0;
  transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

li:hover .arrow-hover {
  left: 0;
  opacity: 1;
}

.primary-menu li:hover a {
  padding-left: 1.875rem !important;
}

.offcanvas-menu .primary-menu ul li:hover > a {
  color: #3b82f6;
}

.offcanvas-menu .primary-menu ul li a.active {
  color: #3b82f6;
  padding-left: 1.875rem !important;
}

.offcanvas-menu .primary-menu ul li a.active .arrow-hover {
  left: 0;
  opacity: 1;
}



