.icon-box{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5rem 0;
    flex-direction: row;
    flex-wrap: wrap;
}
.icon-box .main-border{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.icon-box .border-container.carousel {
    position: relative;
    width: 25vw;
    height: 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 6rem;
}

.icon-box .border-top-left, .border-bottom-right {
    position: absolute;
    border: 2px solid #3a7cf3;
}

.icon-box .border-top-left.carousell {
    top: 0;
    left: 0;
    width: 10vw;
    height: 10vw;
    border-right: none;
    border-bottom: none;
}

.icon-box .border-bottom-right.carousell {
    bottom: 0;
    right: 0;
    width: 10vw;
    height: 10vw;
    border-left: none;
    border-top: none;
}

.icon-box .content {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.icon-box .icon-icon.carousel {
    width: 20vw;
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
}

.icon-box .icon-box-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.icon-box .icon-title {
    text-align: center;
    font-size: 1.6rem;
    margin-top: 1rem;
    color: #fff;
}

.color-white {
    color: #fff;
}
.main-border-div.certificates{
    width: 33%;
    margin-bottom: 6rem;
    display: flex;
    justify-content: center;
}
.icon-box .main-border-div.certificates .border-container.carousel {
   height: 35vw;
}