
.map-and-address{
  margin: 5rem 0 0 0;
}
.map-and-address .container {
    display: flex;
    justify-content: center;
    padding: 1.25rem;
    color: #fff;
    max-width: 95%;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
}
  
  .map-and-address .left {
    
    margin-right: 1.25rem;
    width: 44%;
    border: 2px;
    margin: 0 2rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .map-and-address .image {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }
  
  .map-and-address .right {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 44%;
    margin: 0 0 0 2rem ;
  }
  
  .map-and-address .address {
    margin-bottom: 1.25rem;
  }
  
  .map-and-address .address h2 {
    font-size: 1.5rem;
    margin-bottom: 0.625rem;
  }
  
  .map-and-address .address p {
    font-size: 1rem;
    margin: 5px 0;
  }
  div.map{
    width: 100%;
    height: 100%;
  }
  
  .map-and-address .map iframe {
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0.5rem;
  }
  
  .shadow-contact{
    
    padding: 1rem;
      border-radius: 0.625rem; 
      box-shadow: 0 0 1.25rem 5px #3B82F6; 
  }