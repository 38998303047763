

.container.mining {
    color: white;
    margin: 0 auto;
    padding: 1.25rem;
}

.container.mining .main-title {
    margin-top: 3rem;
    text-align: center;
    color: #3B82F6;
    font-size: 2rem;
    line-height: 5rem;
}

.container.mining .intro, .details, .custom-products, .features-title {
    margin: 1.25rem 0;
    font-size: 1.2rem;
    line-height: 2rem;
}

.container.mining .trademark-title {
    text-align: center;
    font-size: 2rem;
    margin: 1.25rem 0;
    line-height: 2rem;
}

.container.mining .custom-products a {
    color: #3B82F6;
    text-decoration: none;
}

.container.mining .custom-products a:hover {
    text-decoration: underline;
}

.container.mining .features-title {
    font-size: 2rem;
    line-height: 2rem;
    color: white;
    text-align: center;
}

.container.mining .features-list.sustainability.vision {
    list-style-type:disc;
    padding: 0;
}

.container.mining .features-list.sustainability.vision li {
    margin: 1rem 0 0 3rem;
    font-size: 1.2rem;
}

.container.mining .features-list li span {
    color: #3B82F6;
}
