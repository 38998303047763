
.language-selector {
  position: relative;
  display: inline-block;
  color: white;
}

.selected-language {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.8rem;
}

.globe-icon {
  margin-right: 0.5rem;
  width: 1.5rem;
}

.arrow-icon {
  margin-left: 0.5rem;
}

.language-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    color: white;
    z-index: 1000;
    text-align: center;
    padding: 0.625rem 0;
    width: 100%;
    animation: dropdown 0.3s ease forwards;
  }
  
  .language-dropdown-menu.animate-dropdown {
    animation: dropdown 0.3s ease forwards;
    -webkit-animation: dropdown 0.3s ease forwards;
  }
  
  .language-dropdown-menu.animate-dropdown-exit {
    animation: dropdown-exit 0.3s ease forwards;
    -webkit-animation: dropdown-exit 0.3s ease forwards;
  }
  
  .dropdown-item {
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 1.8rem;
    text-align: right;
  }
  
  .dropdown-item:hover {
    background-color: #444;
  }
  
  @keyframes dropdown {
    from {
      opacity: 0;
      transform: translateY(-0.625rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes dropdown-exit {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-0.625rem);
    }
  }
  @keyframes arrow-dropdown {
    from {
      transform:rotate(360deg);
      -webkit-transform:rotate(360deg);
      -moz-transform:rotate(360deg);
      -ms-transform:rotate(360deg);
      -o-transform:rotate(360deg);
  }
    to {
      transform:rotate(180deg);
      -webkit-transform:rotate(180deg);
      -moz-transform:rotate(180deg);
      -ms-transform:rotate(180deg);
      -o-transform:rotate(180deg);
  }
  }
  
  @keyframes arrow-dropdown-exit {
    from {
      transform:rotate(180deg);
      -webkit-transform:rotate(180deg);
      -moz-transform:rotate(180deg);
      -ms-transform:rotate(180deg);
      -o-transform:rotate(180deg);
  }
    to {
      transform:rotate(360deg);
      -webkit-transform:rotate(360deg);
      -moz-transform:rotate(360deg);
      -ms-transform:rotate(360deg);
      -o-transform:rotate(360deg);
  }
  }
  
  .arrow-hover-dropdown.arrow-dropdown{
    animation: arrow-dropdown 0.3s ease forwards;
    -webkit-animation: arrow-dropdown 0.3s ease forwards;
  }
  .arrow-hover-dropdown.arrow-dropdown-exit{
    animation: arrow-dropdown-exit 0.3s ease forwards;
    -webkit-animation: arrow-dropdown-exit 0.3s ease forwards;
  }
  .arrow-hover-dropdown{
    color: white !important;
    width: 1.5rem;
    margin: 0 0 0 1rem;
    text-align: start;
  }
  .primary-menu ul {
    padding-left: 0;
  }
  
.footer-communication-box{
    position: relative;
}

.footer-communication-box .language-selector{
    position: absolute;
    bottom: 1rem;
    right: 0;
}
.footer-communication-box .language-selector .selected-language,
.footer-communication-box .language-selector  .dropdown-item {
     
font-size: 1rem;


}

.footer-communication-box .language-selector .arrow-hover-dropdown{
width: 1rem;
}

.secondary-menu {
    margin-top: 5vh;
}
.secondary-menu .language-selector .selected-language span{
  font-size: 1.8rem;
}