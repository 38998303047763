.container-fluid.banner{
height: 100vh;
}

.container-fluid.banner .row.first_row{
    height: 40vh;
}

.container-fluid.banner .row.first_row .col-12{
    display: flex;
    justify-content: center;
    align-items: end;
    color: white;
    padding-bottom: 3vh;
}

.container-fluid.banner .row.second_row{
    height: 100vh;
    overflow: hidden;
}

.container-fluid.banner .row.second_row .col-12 img{
width: 100%;
}

.about-page .container .about-title{
    margin-top: 2rem;
}

.about-page .container .about-title h1{
    color: white;
    text-align: center;
    font-size: 2.8rem;
    line-height: 3.375rem;

}
.about-page .container .about-title_blue h3{
margin-top: 1rem;
margin-bottom: 2rem;
}
.about-page .container .about-title_blue h3{
    color: #3B82F6;
    font-size: 2rem;
    line-height: 2.5625rem;
}
.about-page .container .about-content p{
    color: white;
    font-size: 1.2rem;    
    line-height: 1.4rem;
}

.about-page .about-title_blue{
    margin: 4rem 0;
}
.about-content{
    margin-bottom: 6rem;
}