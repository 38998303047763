.header {
    padding: 1.25rem 0;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  
  @media only screen and (max-width: 767px) {
    .header {
      padding: 1.25rem 0;
    }
  }
  
  .header.sticky {
    padding: 0.75rem 0;
    background-color: #111;
  }
  
  @media only screen and (max-width: 767px) {
    .header.sticky {
      padding: 0.75rem 0;
    }
  }
  
  .header-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  
  .header-menu-toggler .menu-toggler {
    position: relative;
    font-size: 1.875rem;
    line-height: 1em;
    color: #000;
    background-color: transparent;
    border:none
  }
  
  .header-menu-toggler .menu-toggler svg{
    font-size: 2.5rem; 
  }
  
  
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: transparent linear-gradient(359deg, #3B82F6F2 0%, #08142D71 65%, #02081700 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
  }


  .header-wrapper .logo-box .logo a img{
    width: 12rem;
  }
  .container-fluid.custom-container{
    width: 85%;
  }